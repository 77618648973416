import { z } from 'zod';

export const locales = ['de', 'fr'] as const;
export const localesDirectus = ['de-DE', 'fr-FR'] as const;
export const localeFallback = 'de' as const;

export const localesToDirectusMap: Record<
  (typeof locales)[number],
  (typeof localesDirectus)[number]
> = {
  de: 'de-DE',
  fr: 'fr-FR',
} as const;

export const localesToNextMap: Record<
  (typeof localesDirectus)[number],
  (typeof locales)[number]
> = {
  'de-DE': 'de',
  'fr-FR': 'fr',
} as const;

export const localeIdentifiers = [...locales, ...localesDirectus] as const;

export const localeIdentifiersSchema = z
  .enum(localeIdentifiers)
  .optional()
  .default(localesToDirectusMap[localeFallback]);

export const localeToDirectusLocale = (
  locale: (typeof localeIdentifiers)[number],
) => {
  return (
    localesToDirectusMap[locale as keyof typeof localesToDirectusMap] ?? locale
  );
};
