'use client';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/Button';
import { useRouter } from '@/navigation';
import { Link } from '@/navigation';

type FormValues = {
  email: string;
  password: string;
};

export const SignInForm = () => {
  // States.
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Translations.
  const tForms = useTranslations('forms');
  const tSignIn = useTranslations('sign-in');
  const tGeneral = useTranslations('general');

  // Router.
  const router = useRouter();

  // Form validation schema.
  const schema = z.object({
    email: z.string().min(1, { message: tForms('error-email') }),
    password: z.string().min(1, { message: tForms('error-password') }),
  });

  // Hooks.
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  // Handlers.
  const onSubmit = handleSubmit(async ({ email, password }) => {
    setIsLoading(true);
    signIn('credentials', {
      email,
      password,
      redirect: false,
    })
      .then((response) => {
        if (response?.ok === false) {
          setError(tSignIn('error-general'));
          setIsLoading(false);
        } else {
          router.push('/dashboard');
        }
      })
      .catch(() => {
        setError(tGeneral('error-general'));
        setIsLoading(false);
      });
  });

  // Effects.
  useEffect(() => {
    if (watch('password') === '') {
      setIsPasswordEmpty(true);
    } else {
      setIsPasswordEmpty(false);
    }
  }, [watch('password')]);

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {tForms('label-email')}
        </label>
        <div className="mt-2">
          <input
            {...register('email')}
            type="email"
            className="block w-full rounded-md border-0 bg-gray-50 py-1.5 text-gray-900 shadow-inner ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
          />
          {errors?.email && (
            <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {tForms('label-password')}
        </label>
        <div className="relative mt-2">
          <input
            {...register('password')}
            type={showPassword ? 'text' : 'password'}
            className="block w-full rounded-md border-0 bg-gray-50 py-1.5 text-gray-900 shadow-inner ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
          />
          {!isPasswordEmpty && (
            <div
              className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeSlashIcon className="size-5 text-gray-900" />
              ) : (
                <EyeIcon className="size-5 text-gray-900" />
              )}
            </div>
          )}
          {errors?.password && (
            <p className="mt-2 text-sm text-red-600">
              {errors.password.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end">
        <div className="text-sm leading-6">
          <Link
            href={'/reset-password'}
            className="font-normal text-gray-500 underline underline-offset-4 outline-none hover:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-brand-500"
          >
            {tSignIn('label-forgot-password')}
          </Link>
        </div>
      </div>

      <div>
        <Button isLoading={isLoading} type="submit" className="w-full">
          {tSignIn('label-sign-in')}
        </Button>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      <div>
        <Link href={'/sign-up'} className="font-normal leading-6 text-gray-900">
          <Button className="w-full" type={'button'}>
            {tSignIn('label-sign-up')}
          </Button>
        </Link>
      </div>
    </form>
  );
};
