'use client';

import { useTranslations } from 'next-intl';
import type { ForwardedRef, MouseEventHandler } from 'react';
import React, { forwardRef } from 'react';
import { MoonLoader } from 'react-spinners';
import { twMerge } from 'tailwind-merge';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary' | 'warning' | 'danger';
  style?: React.CSSProperties;
  isDirty?: boolean | undefined;
  isLoading?: boolean;
};

export const Button = forwardRef(
  (
    {
      children,
      onClick,
      className,
      disabled,
      type = 'button',
      size = 'md',
      color = 'primary',
      style,
      isDirty,
      isLoading,
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const primaryClasses = 'bg-gray-900 text-white hover:bg-black';
    const secondaryClasses = 'bg-white text-gray-900 hover:bg-gray-100';
    const warningClasses = 'bg-yellow-500 text-white hover:bg-yellow-600';
    const dangerClasses = 'bg-red-500 text-white hover:bg-red-600';

    const mergedClassNames = twMerge(
      'flex border-2 justify-center items-center h-11 rounded-full px-4 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 transform active:scale-[0.98] transition-transform',
      size === 'sm' && 'h-8 px-3 text-xs',
      size === 'lg' && 'h-12 px-5 text-lg',
      color === 'primary' && primaryClasses,
      color === 'secondary' && secondaryClasses,
      color === 'warning' && warningClasses,
      color === 'danger' && dangerClasses,
      typeof isDirty !== 'undefined' &&
        !isDirty &&
        'opacity-50 cursor-not-allowed',
      className,
    );

    // Translations.
    const t = useTranslations();

    return (
      <button
        className={mergedClassNames}
        disabled={disabled || isLoading}
        onClick={!disabled ? onClick : undefined}
        ref={ref}
        type={type}
        {...(style && { style: style })}
      >
        {typeof isLoading !== 'undefined' && (
          <span className={'absolute flex w-full justify-center'}>
            <MoonLoader
              size={19}
              color={color === 'primary' ? '#fff' : '#000'}
              loading={isLoading}
              aria-label={t('general.label-loading-spinner')}
            />
          </span>
        )}
        {typeof isLoading !== 'undefined' ? (
          <span className={twMerge(isLoading && 'invisible')}>{children}</span>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
